.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.container {
  padding: 150px 10vw;
  text-align: left;
  line-height: 25px;
}

.inner-container {
  margin-bottom: 20px;
}

.page-title {
  font-size: 40px;
  text-align: left;
  padding: 20px 10vw;
  font-weight: bold;
  background-color: purple;
  position: fixed;
  width: 100%;
  z-index: 2;
  color: white
}

.heading {
  font-size: 30px;
  font-weight: bold;
  color: green;
  margin-bottom: 40px;
  border-bottom: 1px solid grey;
  padding-bottom: 10px;
  user-select: none;
}

.main-title {
  font-size: 25px;
  color: purple;
  font-weight: bold;
  user-select: none;
  cursor: pointer;
}

.small-text {
  font-size: 12px;
  text-decoration: underline;
  color: grey
}

.main {
  padding: 20px;
}

.title {
  font-size: 20px;
  margin-bottom: 10px;
  font-weight: bold;
  color: blue;
}

.content {
  font-size: 15px;
  margin-bottom: 20px
}

.modal-container {
  z-index: 3;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 1);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  width: 50vw;
  background-color: white;
  border-radius: 15px;
  padding: 30px 5vw;
  text-align: justify;
}

@media only screen and (max-width: 600px) {
  .modal-content {
    width: 100vw;
    height: 90vh;
    background-color: white;
    border-radius: 15px;
    padding: 50px 5vw;
    text-align: justify;
  }
}

.close-button-container {
  display: flex;
  justify-content: end;
  align-items: center;
  margin-bottom: 20px;
}

.close-button {
  border-radius: 100%;
  font-weight: bold;
  background-color: transparent;
  padding: 5px 10px
}

.footer {
  padding: 20px 0;
  background-color: purple;
  color: white;
  font-weight: bold;
  font-size: 15px;
}
